<template>
     <div class="section">
            <h5 class="text-center">NUESTROS CURSOS</h5>
            <p class="text-center">Cada uno de ellos te acerca más a ser un talento excepcional</p>
            <div class="container mb-5">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-xs-12 mb-4" v-for="course in courses" :key="course.id">
                    <a :href="`/curso/${course.slug}`"><img :src="`/storage/courses/${course.cover}`" class="w-100 rounded" /></a>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import axios from 'axios';

export default ({
   name: 'CoursesHome',
    data() {
      return {
          courses: []
      }
    },
    mounted() {
        this.loadCourses()
            .then((response)=>{
                this.courses = response
        }).catch((error)=>{
            console.log(error);
        })
    },
    methods: {
        loadCourses() {
            return new Promise((resolve, reject)=> {
                axios.get(process.env.VUE_APP_APIURL + 'courses/')
                .then((response)=>{
                    resolve(response.data.data);
                }).catch((error)=>{
                    reject(error);
                })
            })
      }
    }
})
</script>