<template>
    <div class="d-flex flex-column min-vh-100">
        <Header :profile="profile"/>
         <div class="subheader">
           <h3>Nuestros Cursos</h3>
        </div>

        <CoursesList />
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import CoursesList from '@/modules/Home/Courses';

export default {
  components:{
      Header,
      Footer,
      CoursesList
  },
  name: 'Courses',
  data() {
      return {
          profile: this.$store.getters.getUser
      }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.bg-darkBlue{
    background: #152F44
}
.btn-black{ background:#000; color:#FFF; font-size:14px; border:1px solid #979797;}
.btn-black:hover{color:#FFF; background:#333232}
.btn-golden{ background:#AA8D5D; color:#FFF; font-size:14px; border:1px solid #9B7676;}
.btn-golden:hover{color:#FFF; background:#C4A167}

.navbar-light .navbar-nav .nav-link{ color:#FFF}
.navbar-light .navbar-nav .nav-link:hover{ background:#C4A167}
.btn-outline-white{
    border:1px solid #FFF;
    color:#FFF
}
.carousel-caption {top:30%}
.carousel-caption h5{ margin-bottom:30px}
.section{margin:60px 0}
footer{margin-top:60px}
.subheader{ background:#D1C1A7; color:#FFF; display: flex;
  align-items: center;
  justify-content: center; height:250px;
  background-image :url(../../assets/img/bg-cursos.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  }
  .course:hover{
      opacity:.8
  }
</style>
